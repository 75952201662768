(function ($) {
    "use strict";

    var testing = "false";

    Drupal.behaviors.fbc_moveShortTitle = {
        attach: function (context, settings) {
            $(document).ready(function ($) {
                if (
                    $(".group-product-details").length &&
                    $(".field-name-field-short-title").length
                ) {
                    var shortTitle = $(".field-name-field-short-title");

                    shortTitle.once().prependTo("ul.horizontal-tabs-list");
                }
            });
        },
    };

    Drupal.behaviors.fbc_stickyProductTitle = {
        attach: function (context, settings) {
            if ($(".node-type-product-display").length) {
                var tabsOffsetOrig = $("ul.horizontal-tabs-list").offset();

                jQuery(window).scroll(function () {
                    var scrolled = jQuery(window).scrollTop();
                    var tabsOffset = $("ul.horizontal-tabs-list").offset();
                    if (tabsOffset.top >= scrolled) {
                        var offsetDiff = tabsOffset.top - scrolled;
                    } else {
                        var offsetDiff = 0;
                    }

                    if (offsetDiff <= 90 && scrolled >= tabsOffsetOrig.top - 90) {
                        $("ul.horizontal-tabs-list").addClass("sticky-header");
                    } else {
                        $("ul.horizontal-tabs-list").removeClass(" sticky-header");
                    }
                });
            }
        },
    };

    Drupal.behaviors.fbc_addSearchPlaceholder = {
        attach: function (context, settings) {
            $(document).ready(function ($) {
                $("#edit-search-block-form--2").attr("placeholder", "SEARCH");
            });
        },
    };

    Drupal.behaviors.fbc_injectClasses = {
        attach: function (context, settings) {
            $(document).ready(function ($) {
                if ($(".field-name-field-header-banner-image-text").length) {
                    $(".field-name-field-h1-title").addClass("overlay-title");
                }
            });
        },
    };

    Drupal.behaviors.fbc_simpleAccordion = {
        attach: function (context, settings) {
            $(document).ready(function ($) {
                $(".accordion-trigger").click(function (event) {
                    event.preventDefault();
                    if (clicked == false) {
                        $(".accordion-body").slideToggle();
                        clicked = true;
                    } else {
                        $(".accordion-body").slideToggle();
                        clicked = false;
                    }
                });
            });
        },
    };
    Drupal.behaviors.fbc_clickToggle = {
        attach: function (context, settings) {
            // TODO: Make sure that if the user clicks on the toggle
            // it will also close. Mostly in the else below.
            $(".fbc-toggle").click(function (e) {
                $(".fbc-toggle").not($(this)).removeClass("open").addClass("closed");

                if ($(this).hasClass("closed")) {
                    $(this).removeClass("closed").addClass("open");
                } else {
                    $(this).removeClass("open").addClass("closed");
                }
            });

            $("body").click(function (e) {
                var isChild = false;
                if (!$(e.target).hasClass("fbc-toggle")) {
                    $(e.target)
                        .parents()
                        .each(function (index, value) {
                            if ($(value).find("> .fbc-toggle").length > 0) {
                                isChild = true;
                            }
                        });
                    if (!isChild) {
                        $(".fbc-toggle").removeClass("open").addClass("closed");
                    }
                }
            });
        },
    };

    Drupal.behaviors.fbc_demoFormToggle = {
        attach: function (context, settings) {
            if ($("#block-webform-client-block-16").length > 0) {
                $(".block-webform").addClass("closed");

                $(".field-name-title").click(function (e) {
                    if ($("#block-webform-client-block-16").hasClass("open")) {
                        $("#block-webform-client-block-16")
                            .addClass("closed")
                            .removeClass("open");
                    } else {
                        $("#block-webform-client-block-16")
                            .addClass("open")
                            .removeClass("closed");
                    }
                });
                $("body").click(function (e) {
                    var isChild = false;
                    $(e.target)
                        .parents()
                        .each(function (index, value) {
                            if ($(value).is($("#block-webform-client-block-16"))) {
                                isChild = true;
                            }
                        });
                    if (!isChild) {
                        $("#block-webform-client-block-16")
                            .removeClass("open")
                            .addClass("closed");
                    }
                });

                $(".fbc-form-cancel").click(function (e) {
                    $(".block-webform").addClass("closed").removeClass("open");
                    e.stopPropagation();
                });
            }
        },
    };

    Drupal.behaviors.fbc_menuToggle = {
        attach: function (context, settings) {
            // Check if main menu block exists.
            if ($("#block-menu-block-1").length > 0) {
                $(".menu-block-wrapper > ul > li").addClass("fbc-top-level");

                $(".fbc-top-level")
                    .once()
                    .click(function (e) {
                        e.stopPropagation();

                        $(".fbc-top-level").not($(this)).removeClass("open");

                        var isChild = checkChild(e.target, "fbc-top-level", "hasClass");
                        if ($(e.target).hasClass("nolink") && $(this).hasClass("open")) {
                            $(this).removeClass("open");
                        } else {
                            $(this).addClass("open");
                        }
                        if ($(this).hasClass("open")) {
                            if (!isChild) {
                                $(this).removeClass("open");
                            }
                        }
                    });

                $("body")
                    .once()
                    .click(function (e) {
                        var isChild = false;
                        isChild = checkChild(e.target, "fbc-top-level", "hasClass");

                        if (
                            $(e.target).parent().hasClass("open") &&
                            $(e.target).parent().hasClass("fbc-top-level")
                        ) {
                        }
                        if (isChild === false) {
                            $(".fbc-top-level").removeClass("open");
                        }
                    });
            }
        },
    };

    var checkChild = function (target, parentSelector, operator, relationship) {
        var isChild = false;

        $(target)
            .parents()
            .each(function (index, value) {
                if (operator === "hasClass") {
                    if ($(value).hasClass(parentSelector)) {
                        isChild = true;
                    }
                }
                if (operator === "is") {
                    if ($(value).is($(parentSelector))) {
                        isChild = true;
                    }
                }
            });

        return isChild;
    };

    Drupal.behaviors.fbc_accessorySearchToggle = {
        attach: function (context, settings) {
            var prodView = ".view-fbc-accessory-search.view-display-id-block_2";
            var accView = ".view-fbc-accessory-search.view-display-id-block";
            var prod =
                '$(accView).parentsUntil( $( ".field-type-paragraphs" ), ".field-item" )';
            var acc =
                '$(prodView).parentsUntil( $( ".field-type-paragraphs" ), ".field-item" )';
            if ($(".page-node-19533").length > 0) {
                // Change to products.
                $('[data-action="toggle-products"]').click(function () {
                    $(accView)
                        .parentsUntil($(".field-type-paragraphs"), ".field-item")
                        .addClass("inactive")
                        .removeClass("active");
                    $(prodView)
                        .parentsUntil($(".field-type-paragraphs"), ".field-item")
                        .addClass("active")
                        .removeClass("inactive");
                    $('[data-action="toggle-products"]')
                        .addClass("active")
                        .removeClass("inactive");
                    $('[data-action="toggle-accessories"]')
                        .removeClass("active")
                        .addClass("inactive");
                });
                // Change to accessories.
                $('[data-action="toggle-accessories"]').click(function () {
                    $(prodView)
                        .parentsUntil($(".field-type-paragraphs"), ".field-item")
                        .addClass("inactive")
                        .removeClass("active");
                    $(accView)
                        .parentsUntil($(".field-type-paragraphs"), ".field-item")
                        .addClass("active")
                        .removeClass("inactive");
                    $('[data-action="toggle-accessories"]')
                        .addClass("active")
                        .removeClass("inactive");
                    $('[data-action="toggle-products"]')
                        .removeClass("active")
                        .addClass("inactive");
                });
            }
        },
    };

    Drupal.behaviors.fbcProdMenuToggle = {
        attach: function (context, settings) {
            var prodMenu = ".horizontal-tabs-list";

            if ($(".node-type-product-display").length) {
                $(".horizontal-tabs-list").click(function () {
                    if ($(".horizontal-tabs-list").hasClass("header-opened")) {
                        $(".horizontal-tabs-list").removeClass("header-opened");
                    } else {
                        $(".horizontal-tabs-list").addClass("header-opened");
                    }
                });

                $(".horizontal-tab-button").click(function () {
                    $(".horizontal-tab-button")
                        .parentsUntil($(".horizontal-tabs-list"))
                        .removeClass("header-opened");
                });
            }
        },
    };

    Drupal.behaviors.fbc_toggleForm = {
        attach: function (context, settings) {
            $(document).ready(function ($) {
                if ($(".field-name-field-form-block").length) {
                    var quickQuote = "#block-webform-client-block-5953";
                    var quoteDemo = "#block-webform-client-block-19550";

                    $(quoteDemo).addClass("invisible");
                    $(quickQuote).addClass("invisible");

                    $.get(
                        "https://ipinfo.io",
                        function (response) {
                            if (testing === "true") {
                                var now = new Date(Date.now());
                                var hours = now.getHours();
                                var localOffset = d.getTimezoneOffset() / 60;

                                console.log(hours);
                                console.log(localOffset);
                            }

                            if ((response.country = "US")) {
                                // Show quickQuote form for US only.
                                $(quoteDemo).addClass("invisible").removeClass("visible");
                                $(quickQuote).addClass("visible").removeClass("invisible");
                            } else {
                                $(quoteDemo).addClass("visible").removeClass("invisible");
                                $(quickQuote).addClass("invisible").removeClass("visible");
                            }
                        },
                        "jsonp"
                    );
                }
            });
        },
    };
    Drupal.behaviors.fbc_menuScroll = {
        attach: function (context, settings) {
            var menuOffsetOrig = $(".navbar").offset();
            if ($(window).width() > 768) {
                jQuery(window).scroll(function () {
                    var menuScrolled = jQuery(window).scrollTop();
                    var menuOffset = $(".navbar").offset();

                    if (menuOffset.top >= menuScrolled) {
                        var offsetDiff = menuOffset.top - menuScrolled;
                    } else {
                        var offsetDiff = 0;
                    }
                    if (offsetDiff <= 0 && menuScrolled >= menuOffsetOrig.top) {
                        $("body").addClass("sticky-navbar");
                    } else {
                        $("body").removeClass("sticky-navbar");
                    }
                });
            }

        },
    };

    Drupal.behaviors.fbc_searchButton = {
        attach: function (context, settings) {
            // Prevent click on search button from submitting empty query.

            $("#edit-submit-fbc-search").click(function (event) {
                event.preventDefault();
                var $q, qval, qinit;
                $q = $("#views-exposed-form-fbc-search-page-1 .form-text").first();
                qval = $q.val();
                qinit = $q.data("initial");

                if (qval === "" || qval === qinit) {
                    $q.focus();
                } else {
                    $("#views-exposed-form-fbc-search-page-1").submit();
                }
            });
        },
    };

    Drupal.behaviors.fbc_wrapIframe = {
        attach: function (context, settings) {
            // Adds a wrapper class around iframes so they can be responsive.
            if ($(".main-container").length) {
                if ($("iframe").parents(".video-container").length < 1) {
                    $(".main-container iframe")
                        .once()
                        .wrap("<div class='video-container'></div>");
                }
            }
        },
    };

    Drupal.behaviors.fbc_showMiniSiteMenu = {
        attach: function (context, settings) {

            $(document).ready(function ($) {
                if (!(window.location.href.indexOf("oneqa") > -1) ){
                    $(".field-name-oneqa-navigation").remove();
                }
            });
        },
    };


    Drupal.behaviors.fbc_revalidateBlazy = {
        attach: function (context, settings) {

            $('.quicktabs-tabs a.quicktabs-tab').on('click', function (e) {
                // Show related content...
                $(document).find('.slick__slider').each(function(e,f){
                    $(f).slick('reinit')});
              });
        },
    };

})(jQuery);
